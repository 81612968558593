

































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductInformation } from '@/types/neuhaus-website/cms/pages/products';
import { Grid, GridRow } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * CMSAdminComponentProductInformationList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-product-nformation-form-dialog': () => import(
            './CMSAdminComponentProductInformationFormDialog.vue'
        )
    }
})
export default class CMSAdminComponentProductInformationList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all productInformationCards
    @Prop({ type: Object as () => Grid<ProductInformation> }) readonly productInformations: Grid<ProductInformation> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited productInformation
    private editedProductInformationItem: GridRow<ProductInformation> = {
        id: -1,
        cols: [
            {
                id: -1,
                content: {
                    header: '',
                    content: ''
                }
            },
            {
                id: -1,
                content: {
                    header: '',
                    content: ''
                }
            }
        ]
    };

    // Default productInformation item values
    private defaultProductInformationItem: GridRow<ProductInformation> = {
        id: -1,
        cols: [
            {
                id: 0,
                content: {
                    header: '',
                    content: ''
                }
            },
            {
                id: 1,
                content: {
                    header: '',
                    content: ''
                }
            }
        ]
    }

    // The index of the edited productInformation (-1 = new productInformation)
    private editedProductInformationIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.header1').toString(),
                value: 'cols[0].content.header'
            },
            {
                text: this.$t('table.headers.header2').toString(),
                value: 'cols[1].content.header'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the ProductInformationFormDialog to create a new productInformation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addProductInformation() {
        if (this.productInformations) {
            const newProductInformation = CloneHelper.deepCloneObject(this.defaultProductInformationItem);

            // get maxId
            let maxId = -1;
            this.productInformations.rows.forEach(row => {
                if (row.id > maxId) maxId = row.id;
            });
            newProductInformation.id = maxId +1;

            this.editedProductInformationItem = newProductInformation;
            this.editedProductInformationIndex = -1;
            this.formDialogMode = 'create';
            this.showFormDialog = true;
        }
    }

    /**
     * Opens the ProductInformationFormDialog to edit the given productInformation element
     * 
     * @param productInformationItem productInformation item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async editProductInformation(productInformationItem: GridRow<ProductInformation>) {
        if (this.productInformations) {
            const productInformation = CloneHelper.deepCloneObject(productInformationItem);

            this.editedProductInformationItem = productInformation;
            this.editedProductInformationIndex = this.productInformations.rows.indexOf(productInformationItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given productInformation
     * 
     * @param productInformationItem productInformation item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async deleteProductInformation(productInformationItem: GridRow<ProductInformation>) {
        if (this.productInformations) {

            this.editedProductInformationItem = productInformationItem;
            this.editedProductInformationIndex = this.productInformations.rows.indexOf(productInformationItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedProductInformation(formDialogMode: string, productInformationItemIndex: number, productInformationItem: GridRow<ProductInformation>) {
        if (this.productInformations) {
            if (formDialogMode == 'create') {
                this.productInformations.rows.push(productInformationItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.productInformations.rows[productInformationItemIndex], productInformationItem);
            }
            else if (formDialogMode == 'delete') {
                this.productInformations.rows.splice(productInformationItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
